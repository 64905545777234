import RoutePaths from '../../../utilities/RoutePaths';
import Colors from '../../../utilities/Colors';
import Icons from '../../../utilities/Icons';
import { useNavigate } from 'react-router';
import Tooltip from '../generic/Tooltip';

const Back = () => {
    const navigate = useNavigate();

    return (
        <div className="navbar-body" style={{ '--borderColor': Colors.outline, top: 0, borderRadius: '0 0 30px 0' }}>
            <div className="widget-wrapper">
                <Tooltip message={"Home"}>
                    <p onClick={() => navigate(RoutePaths.HOME)} className="icon" style={{ color: Colors.text }}><i className={Icons.HOME}></i></p>
                </Tooltip>
            </div>
        </div>
    );
}

export default Back;
