const Icons = Object.freeze({
    STAR: 'fa-solid fa-star',
    CODE_FORK: 'fa-solid fa-code-fork',
    HOME: 'fa-solid fa-house',
    GITHUB: 'fa-brands fa-github',
    ARROW_LEFT: 'fa-solid fa-arrow-left-long',
    CIRCLE_INFORMATION: 'fa-solid fa-circle-info',
});

export default Icons;
