import { useEffect, useRef, useState } from 'react';
import Colors from '../../utilities/Colors';
import Tooltip from '../partials/generic/Tooltip';
import RepoCard from '../partials/generic/RepoCard';
import RenderEducationCard from '../partials/generic/education/RenderEducationCard';
import RenderContributions from '../partials/generic/contributions/RenderContributions';
import Projects from './projects/Projects';
import Icons from '../../utilities/Icons';
import axios from 'axios';
import RoutePaths from '../../utilities/RoutePaths';
import { useNavigate } from 'react-router';

const Home = () => {
    const [repos, setRepos] = useState([]);
    const [sortedRepos, setSortedRepos] = useState([]);
    const [github, setGitHub] = useState([]);
    const [sortBy, setSortBy] = useState('date');
    const [subheader, setSubheader] = useState('');
    const [currentColor, setCurrentColor] = useState(0);
    const [color, setColor] = useState(Colors.widgets.aqua);
    const [currentChar, setCurrentChar] = useState(0);
    const [currentSubtitle, setCurrentSubtitle] = useState(0);
    const [currentSkill, setCurrentSkill] = useState(0);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    const navigate = useNavigate();
    const cursorRef = useRef();

    const subheaderValues = [
        "Front-end developer",
        "Back-end developer",
        "Full-stack developer",
        "Software engineer"
    ];

    const technologiesValues = [
        "JavaScript",
        "Java",
        "ReactJS",
        "MySQL",
        "CSS",
        "HTML"
    ];

    axios.defaults.withCredentials = true;

    useEffect(() => {
        document.title = process.env.REACT_APP_TITLE;

        establishRepos();
        establishGitHub();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentChar >= subheaderValues[currentSubtitle].length + 15) {
                setSubheader([]);
                setCurrentChar(0);
                setCurrentSubtitle(prevSubtitle => prevSubtitle >= subheaderValues.length - 1 ? 0 : prevSubtitle + 1);
            } else {
                setSubheader(prev => [...prev, subheaderValues[currentSubtitle][currentChar]]);
                setCurrentChar(prevChar => prevChar + 1);
            }
        }, 100 + Math.random() * 100);

        return () => {
            clearInterval(interval);
        };
    }, [currentChar, currentSubtitle]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentSkill >= technologiesValues.length - 1) {
                setCurrentSkill(0);
                return;
            }
            setCurrentSkill(currentSkill + 1);
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, [currentSkill, technologiesValues.length]);

    useEffect(() => {
        const handleMouseMove = (event) => {
            setCursorPosition({ x: event.clientX, y: event.clientY+window.scrollY });
        };
    
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('scroll', handleMouseMove);
    
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('scroll', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        if (cursorRef.current) {
            cursorRef.current.style.transform = `translate(${cursorPosition.x - 7.5}px, ${cursorPosition.y - 7.5}px)`;
        }
    }, [cursorPosition]);

    const establishRepos = () => {
        fetch(`https://api.github.com/users/${process.env.REACT_APP_GITHUB_USERNAME}/repos`)
            .then((res) => res.json())
            .then((data) => {
                setRepos(data);
            })
            .catch((error) => console.error('Error fetching GitHub:', error));
    };

    const establishGitHub = () => {
        fetch(`https://github-contributions-api.jogruber.de/v4/${process.env.REACT_APP_GITHUB_USERNAME}`)
            .then((res) => res.json())
            .then((data) => {
                setGitHub(data);
            })
            .catch((error) => console.error('Error fetching GitHub:', error));
    };

    const switchColor = () => {
        if (currentColor >= Object.keys(Colors.widgets).length-1) {
            setCurrentColor(0);
            setColor(Colors.widgets[Object.keys(Colors.widgets)[0]]);
        } else {
            setColor(Colors.widgets[Object.keys(Colors.widgets)[currentColor+1]]);
            setCurrentColor(currentColor+1);
        }
    }

    const renderTechnologies = () => {
        return technologiesValues.map((skill, index) => (
            <p className="item-text" style={{ color: currentSkill === index ? color.button : Colors.text }}>{skill}</p>
        ));
    };

    const renderProjects = () => {
        return Object.keys(Projects).map((key) => (
            <div className="item-card" onClick={() => navigate(`${RoutePaths.PROJECT}/${Projects[key].link}`)} style={{ '--borderColor': Colors.outline, '--hoverColor': Colors.base }}>
                <p className="large-text" style={{ color: Colors.text }}>{Projects[key].title}</p>
                <p className="small-text" style={{ color: 'grey' }}>{Projects[key].description}</p>
            </div>
        ));
    }

    const renderRepos = () => {
        return sortedRepos.map((repo) => (
            <div key={repo.id} onClick={() => window.open(repo.html_url)}>
                <RepoCard repo={repo} color={color}/>
            </div>
        ));
    };

    const fetchSortColor = (sort) => {
        return sortBy === sort ? color.button : Colors.button;
    }

    useEffect(() => {
        let sortedData = [...repos];

        if (sortBy === 'stars') {
            sortedData = sortedData.sort((a, b) => b.stargazers_count - a.stargazers_count);
        } else if (sortBy === 'forks') {
            sortedData = sortedData.sort((a, b) => b.forks - a.forks);
        } else {
            sortedData = sortedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        }

        setSortedRepos(sortedData);
    }, [repos, sortBy]);

    return (
        <>
            <div className="background-cursor" ref={cursorRef}></div>
            <div className="bordered-body">
                <div className="title-container">
                    <p className="title" onClick={switchColor} style={{ color: color.button, cursor: 'pointer' }}>Joseph Gardiner</p>
                    <p style={{ color: Colors.text }} className="medium-text">{subheader}...</p>
                </div>

                <fieldset style={{ borderColor: Colors.outline }}>
                    <legend style={{ color: Colors.text }}>
                        {'Education '}
                        <Tooltip width='300px' message={"All information is displayed in accordance to the Australian school system."}>
                            <button className="information-button" style={{ color: Colors.text, '--borderColor': Colors.outline }}><i className={Icons.CIRCLE_INFORMATION}/></button>
                        </Tooltip>
                    </legend>
                    <RenderEducationCard color={color}/>
                </fieldset>

                <fieldset style={{ borderColor: Colors.outline }}>
                    <legend style={{ color: Colors.text }}>Technologies</legend>
                    <div className="item-container" style={{ margin: 10 }}>
                    {renderTechnologies()}
                    </div>
                </fieldset>

                <fieldset style={{ borderColor: Colors.outline }}>
                    <legend style={{ color: Colors.text }}>Projects</legend>
                    <div className="item-container" style={{ margin: 10 }}>
                        {renderProjects()}
                    </div>
                </fieldset>

                <fieldset style={{ borderColor: Colors.outline }}>
                    <legend style={{ color: Colors.text }}>Repositories ({repos.length})</legend>
                    <div className="center">
                        <div className="sort-by-container">
                            <p className="large-text" style={{ color: Colors.text }}>
                                <span onClick={() => setSortBy('date')} style={{ cursor: 'pointer', color: fetchSortColor('date') }}>date</span>{` | `}
                                <span onClick={() => setSortBy('stars')} style={{ cursor: 'pointer', color: fetchSortColor('stars') }}>stars</span>{` | `}
                                <span onClick={() => setSortBy('forks')} style={{ cursor: 'pointer', color: fetchSortColor('forks') }}>forks</span>
                            </p>
                            <div className="underline" style={{ marginLeft: sortBy === 'date' ? '0%' : sortBy === 'stars' ? '35%' : '75%' }} />
                        </div>
                    </div>
                    <div className="item-container" style={{ '--borderColor': Colors.outline }}>
                        {renderRepos()}
                    </div>
                </fieldset>

                {github && github.total ? (
                    <fieldset style={{ borderColor: Colors.outline }}>
                        <legend style={{ color: Colors.text }}>GitHub Contributions ({github.total[String(new Date().getFullYear())]})</legend>
                        <div className="item-container" style={{ '--borderColor': Colors.outline }}>
                            <RenderContributions data={github.contributions.filter(val => val.date.startsWith(String(new Date().getFullYear())))} color={color}/>
                        </div>
                    </fieldset>
                ) : null}
            </div>
        </>
    );
};

export default Home;
