import './css/style.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RoutePaths from './utilities/RoutePaths.js';
import React from 'react';

import Navbar from './components/partials/navbar/navbar.js';
import Back from './components/partials/navbar/back.js';

import Home from './components/user/home.js';

import SocialMedia from './components/user/projects/social-media.js';

import Error from './components/generic/error.js';
import Projects from './components/user/projects/Projects.js';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path={RoutePaths.HOME} element={<><Navbar/><Home/></>}/>

                <Route path={`${RoutePaths.PROJECT}/${Projects.SOCIAL_MEDIA_PLATFORM.link}`} element={<><Back/><SocialMedia/></>}/>

                <Route path={RoutePaths.ERROR} element={<Error/>}/>
            </Routes>
        </Router>
    );
};

export default App;
