import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Button from '../partials/generic/Button.js';
import RoutePaths from '../../utilities/RoutePaths.js';
import Colors from '../../utilities/Colors.js';

const Error = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Error`;
    }, []);

    return (
        <>
            <div className="center" style={{ marginTop: 300 }}>
                <p className="title" style={{ color: Colors.widgets.aqua.primary }}>An error has been caught</p>
                <p className="medium-text" style={{ color: Colors.text }}>An error has occured loading this content - please try another page.</p>
                <Button text={"Home"} action={() => navigate(RoutePaths.HOME)}/>
            </div>
        </>
    );
}

export default Error;
