const Education = Object.freeze({
    ELEMETARY_EDUCATION: {
        title: "Elementary School",
        location: "NSW, Australia",
        start_year: 2011,
        completed_year: 2018,
    },
    HIGH_SCHOOL: {
        title: "High School",
        location: "NSW, Australia",
        start_year: 2018,
        completed_year: 2023,
    },
    UNIVERSITY: {
        title: "Griffith University",
        course: "Bachelor of Engineering (honours)",
        location: "QLD, Australia",
        start_year: 2024,
        completed_year: 2028
    }
});

export default Education;
