import './contributions.css';

import Colors from '../../../../utilities/Colors';
import Tooltip from '../Tooltip';

const RenderContributions = ({data, color=Colors.widgets.aqua}) => {
    const getColor = (contribute) => {
        if (contribute.level === 0) return color.contribution_level_one;
        if (contribute.level === 1) return color.contribution_level_two;
        else return color.contribution_level_three;
    };

    const groupByWeeks = (data) => {
        const weeks = [];
        data.forEach((contribute) => {
            const weekIndex = new Date(contribute.date).getWeek();
            if (!weeks[weekIndex]) {
                weeks[weekIndex] = [];
            }
            weeks[weekIndex].push(contribute);
        });
        return weeks;
    };

    Date.prototype.getWeek = function() {
        const firstDayOfYear = new Date(this.getFullYear(), 0, 1);
        const days = Math.floor((this - firstDayOfYear) / (24 * 60 * 60 * 1000));
        return Math.ceil((days + 1) / 7);
    };

    const groupedData = groupByWeeks(data);

    return (
        <div className="contributions-grid">
            {groupedData.map((week, weekIndex) => (
                <div key={weekIndex} className="week-column">
                    {week.map((contribute, index) => (
                        <Tooltip key={index} width={185} message={`${contribute.count} contributions on ${contribute.date}`}>
                            <div className="contribute-entry" onClick={() => window.open(`https://www.github.com/${process.env.REACT_APP_GITHUB_USERNAME}`)} style={{ backgroundColor: getColor(contribute) }}/>
                        </Tooltip>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default RenderContributions;
