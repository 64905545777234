import { useEffect, useRef, useState } from "react";
import Colors from "../../../utilities/Colors";
import Divider from "../../partials/generic/Divider";
import SocialMediaGateway from '../../../resources/media/social-media-gateway.png';
import SocialMediaHome from '../../../resources/media/social-media-home.png';
import SocialMediaGeneral from '../../../resources/media/social-media-general.png';

const SocialMedia = () => {
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    const cursorRef = useRef();

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Project / Social media`;
    }, []);

    useEffect(() => {
        const handleMouseMove = (event) => {
            setCursorPosition({ x: event.clientX, y: event.clientY+window.scrollY });
        };
    
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('scroll', handleMouseMove);
    
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('scroll', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        if (cursorRef.current) {
            cursorRef.current.style.transform = `translate(${cursorPosition.x - 7.5}px, ${cursorPosition.y - 7.5}px)`;
        }
    }, [cursorPosition]);

    return (
        <>
            <div className="background-cursor" ref={cursorRef}></div>
            <div className="bordered-body">
                <p className=" center title" style={{ color: Colors.widgets.aqua.button, fontWeight: 400 }}>Social media platform</p>
                <div style={{ margin: '10px 0' }}><Divider color={Colors.outline}/></div>
                <div style={{ textAlign: 'justify' }}>
                    <div className="flex-container">
                        <p className="medium-text" style={{ color: Colors.text }}>This full-stack social media website built using ReactJS, ExpressJS, and MySQL is packed with almost 15,000 lines of code. With a similar style from Twitter and Instagram, this social media website comes with all basic functionality you would expect and a lot more, such as media expansion, bookmarks, communities, reports, blocks, and a lot more.</p>
                        <fieldset style={{ width: 3000, margin: '0 0 0 20px', borderColor: Colors.outline }}>
                            <legend style={{ color: Colors.text }}>Technologies</legend>
                            <div className="item-container">
                                <div className="outlined-view" style={{ '--borderColor': Colors.outline }}>
                                    <p className="large-text" style={{ color: Colors.widgets.aqua.button }}>ReactJS</p>
                                    <p className="small-text" style={{ color: Colors.text }}>Front-end</p>
                                </div>
                                <div className="outlined-view" style={{ '--borderColor': Colors.outline }}>
                                    <p className="large-text" style={{ color: Colors.widgets.aqua.button }}>ExpressJS</p>
                                    <p className="small-text" style={{ color: Colors.text }}>Back-end</p>
                                </div>
                                <div className="outlined-view" style={{ '--borderColor': Colors.outline }}>
                                    <p className="large-text" style={{ color: Colors.widgets.aqua.button }}>MySQL</p>
                                    <p className="small-text" style={{ color: Colors.text }}>Database</p>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="center">
                    <div style={{ margin: '10px 0' }}><Divider color={Colors.outline}/></div>
                    <img className="project-image" alt='' src={SocialMediaGateway}/>
                    <p className="medium-text" style={{ color: 'grey' }}>The login and signup functionality is all located on the same page using overlays, with minimal buttons used where possible, this eliminates unnessary confusion to the user while delivering a good user interface.</p>
                    <br/>

                    <div style={{ margin: '10px 0' }}><Divider color={Colors.outline}/></div>
                    <img className="project-image" alt='' src={SocialMediaHome}/>
                    <p className="medium-text" style={{ color: 'grey' }}>The homepage takes inspiration from familar websites but aims to keep the design modern and clean. Utilizing overlays and menus, the user is able to access most functionality with no more than 2 clicks, keep it simple and easy to control.</p>
                    <br/>

                    <div style={{ margin: '10px 0' }}><Divider color={Colors.outline}/></div>
                    <img className="project-image" alt='' src={SocialMediaGeneral}/>
                    <p className="medium-text" style={{ color: 'grey' }}>Admin functionality includes in-depth reporting, user overviews, along with user impersonation. With that, users are able to join different communities, customize their profile, and adjust their account settings.</p>
                    <br/>
                    <div style={{ margin: '10px 0' }}><Divider color={Colors.outline}/></div>
                </div>
            </div>
        </>
    );
};

export default SocialMedia;
