import Colors from '../../../../utilities/Colors';
import Divider from '../Divider';

import { useState, useEffect, useRef } from 'react';

const EducationPopup = ({education, children}) => {
    const [visible, setVisible] = useState(false);

    const year = new Date().getFullYear();

    const containerRef = useRef(null);
    const hoverTimeout = useRef(null);

    useEffect(() => {
        return () => {
            if (hoverTimeout.current) {
                clearTimeout(hoverTimeout.current);
            }
        };
    }, []);

    const handleMouseEnter = () => {
        hoverTimeout.current = setTimeout(() => setVisible(true), process.env.REACT_APP_EDUCATION_POPUP_DELAY);
    };

    const calcCompleted = () => {
        return education.completed_year < year;
    }

    const calcCompletedYears = () => {
        if (calcCompleted()) {
            const val = education.completed_year-education.start_year;
            return `${val}/${val}`;
        }
        const period = education.completed_year-education.start_year;
        return `${education.completed_year-year-period}/${education.completed_year-education.start_year}`;
    }

    const calcPercentComplete = () => {
        const period = education.completed_year - education.start_year;
        const elapsed = year - education.start_year;
    
        if (calcCompleted() || period <= 0) {
            return 100;
        }
        return Math.round((elapsed / period) * 100);
    };
    

    const handleMouseLeave = () => {
        clearTimeout(hoverTimeout.current);
        setVisible(false);
    };

    return (
        <div ref={containerRef} className="inline-container" style={{ cursor: 'pointer' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {visible ? (
                <div className="menu-container" style={{ width: 250, padding: 10, color: Colors.text, '--backgroundColor': Colors.base }}>
                    <p style={{ color: Colors.text, fontSize: '1.5em' }}>{education.title}</p>
                    {education.course ? (
                        <p style={{ color: Colors.text }}>{education.course}</p>
                    ) : null}
                    <span style={{ color: 'grey' }}>{education.location}</span>
                    <div style={{ margin: '5px 0' }}><Divider color={Colors.outline}/></div>
                    <p style={{ color: calcCompleted() ? `rgb(${process.env.REACT_APP_COLOR_GREEN})` : `rgb(${process.env.REACT_APP_COLOR_YELLOW})` }}>{calcCompleted() ? "Completed" : "In-progress"}
                        <span style={{ color: 'grey' }}> {calcCompletedYears()} years ({`${calcPercentComplete()}%`})</span>
                    </p>
                    <div className="education-progress-body" style={{ backgroundColor: 'black' }}>
                        <div className="education-progress" style={{ '--educationProgress': `${calcPercentComplete()}%`, backgroundColor: calcCompleted() ? `rgb(${process.env.REACT_APP_COLOR_GREEN})` : `rgb(${process.env.REACT_APP_COLOR_YELLOW})`}}/>
                    </div>
                </div>
            ) : null}
            <div>{children}</div>
        </div>
    );
};

export default EducationPopup;
