const Colors = Object.freeze({
    text: "rgb(255, 255, 255)",
    base: "rgb(18, 18, 18)",
    outline: "rgb(47, 51, 54)",
    widgets: {
        aqua: {
            primary: "rgb(77, 128, 163)",
            button: "rgb(132, 188, 227)",
            button_hover: "rgb(77, 128, 163)",
            ping: "rgb(160, 213, 250)",
            contribution_level_one: 'rgb(18, 18, 18)',
            contribution_level_two: 'rgb(77, 128, 163)',
            contribution_level_three: 'rgb(160, 213, 250)'
        },
        pink: {
            primary: "rgb(163, 77, 152)",
            button: "rgb(227, 132, 214)",
            button_hover: "rgb(163, 77, 152)",
            ping: "rgb(250, 160, 240)",
            contribution_level_one: 'rgb(18, 18, 18)',
            contribution_level_two: 'rgb(163, 77, 153)',
            contribution_level_three: 'rgb(250, 160, 240)'
        },
        green: {
            primary: "rgb(78, 163, 77)",
            button: "rgb(138, 227, 132)",
            button_hover: "rgb(77, 163, 78)",
            ping: "rgb(160, 250, 166)",
            contribution_level_one: 'rgb(18, 18, 18)',
            contribution_level_two: 'rgb(84, 163, 77)',
            contribution_level_three: 'rgb(170, 250, 160)'
        },
    }
});

export default Colors;
