import { useState, useRef, useEffect } from "react";
import Colors from '../../../utilities/Colors.js';

const Tooltip = ({width='100%', message, children}) => {
    const [visible, setVisible] = useState(false);
    const hoverTimeout = useRef(null);
    
    useEffect(() => {
        return () => {
            if (hoverTimeout.current) {
                clearTimeout(hoverTimeout.current);
            }
        };
    }, []);

    
    const handleMouseEnter = () => {
        hoverTimeout.current = setTimeout(() => setVisible(true), 500);
    };

    const handleMouseLeave = () => {
        clearTimeout(hoverTimeout.current);
        setVisible(false);
    };

    return (
        <div className="inline-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {visible ? (
                <div className="tooltip" style={{ width: width, color: Colors.text, '--backgroundColor': Colors.hover, '--borderColor': Colors.outline }}>
                    <p>{message}</p>
                </div>
            ) : null}
            {children}
        </div>
    );
}

export default Tooltip;
