import Colors from '../../../utilities/Colors';
import Icons from '../../../utilities/Icons';
import Tooltip from '../generic/Tooltip';

const Navbar = () => {
    return (
        <div className="navbar-body" style={{ '--borderColor': Colors.outline }}>
            <div className="widget-wrapper">
                <Tooltip message={"GitHub"}>
                    <p onClick={() => window.open(`https://github.com/${process.env.REACT_APP_GITHUB_USERNAME}`, "_blank")} className="icon" style={{ color: Colors.text }}><i className={Icons.GITHUB}></i></p>
                </Tooltip>
            </div>
        </div>
    );
}

export default Navbar;
